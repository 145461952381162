import Pusher from "pusher-js";
import store from "@/store";
import get from "lodash/get";
export * from "./modules/texting";
export * from "./modules/timeclock";

const authHeaders = () => store.getters["auth/authHeaders"];
const storeChannelName = () => store.getters["sockets/storeChannelName"];

export const pusher = (headers = null) => {
  headers = headers || authHeaders();
  return new Pusher(process.env.VUE_APP_PUSHER_KEY, {
    cluster: process.env.VUE_APP_PUSHER_CLUSTER,
    encrypted: true,
    authEndpoint: `${process.env.VUE_APP_RAI_DOMAIN}/pusher/auth`,
    auth: {
      headers: {
        ...headers,
      },
    },
    disableStats: true,
    forceTLS: true,
  });
};

// Create a pusher client and subscribe to the
// private store channel
export const subscribeToStoreChannel = async () => {
  const pusherClient = await pusher(authHeaders);
  let channel = pusherClient.channel(storeChannelName());
  if (!channel) {
    channel = pusherClient.subscribe(storeChannelName());
  }
  return pusherClient;
};

// This will find all module files under `modules`,
// load the module, and call its `init` function
// if it exists. This allows for easy event
// binding on store switches and such.
//
// Called from App.vue
export const init = (pusher, apollo) => {
  const MODULES = ["pos", "tablet", "texting", "timeclock"];

  MODULES.map((m) => require(`./modules/${m}`)).forEach(
    ({ init }) => init && init(pusher, apollo)
  );
};

// Convenience method to get the current store's presence channel
// from a provided pusher instance
export const presenceChannel = (pusher) => {
  return pusher.channel(store.getters["sockets/storePresenceName"]);
};

// Unsubscribes all currently subscribed channels
const unsubscribeAll = (pusher) => {
  pusher.channels.all().map((channel) => {
    pusher.unsubscribe(channel.name);
  });
  return pusher;
};

// This function should be used to subscribe to all necessary
// channels. It should be considered the master `setup subscriptions`
// function.
// Also, this function calls the init function, which calls all
// sub-module `init` functions
const subscribeAll = async (pusher, apollo, isRaiPos = false) => {
  pusher = setAuth(pusher);
  console.log(
    "utils/sockets/index:subscribeAll",
    store.getters["sockets/storeChannelName"],
    store.getters["sockets/storePresenceName"]
  );
  const now = new Date();

  // Use a promise to wait for auth to be valid
  const channelNamePromise = new Promise((resolve, reject) => {
    const interval = setInterval(() => {
      if (!get(store, "state.auth.accountId")) return;
      if (new Date() - now > 10000)
        reject(
          "Error connecting to channels. Could not connect get account_id within 10 seconds"
        );

      clearInterval(interval);
      resolve(true);
    }, 500);
  });

  // This is a little hacky, but it ensures auth is ready
  const result = channelNamePromise
    .then(() => {
      pusher.subscribe(store.getters["sockets/storeChannelName"]);

      // Only subscibe to the presence channel if we're running on a raiPos-enabled device
      if (isRaiPos) {
        const presenceChannel = pusher.subscribe(
          store.getters["sockets/storePresenceName"]
        );
        presenceChannel.bind("pusher:subscription_succeeded", () => {
          const {
            members: {
              me: { id: userId, info: userInfo },
            },
          } = presenceChannel;
          /* eslint-disable no-console */
          console.log(`"present as ${userId}:"`);
          /* eslint-disable no-console */
          console.log(userInfo);
        });
      }
      init(pusher, apollo);
      return pusher;
    })
    .catch((err) => {
      console.error(err);
      return pusher;
    });

  return result;
};

const setAuth = (pusher) => {
  pusher.config.auth = { headers: { ...store.getters["auth/authHeaders"] } };
  return pusher;
};

export const RaiPusher = {
  unsubscribeAll,
  subscribeAll,
};

export default {
  pusher,
  subscribeToStoreChannel,
};
